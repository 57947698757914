.left-panel-container {
  height: 100vh;
  background-color: #3C3C3C;
  color: #fff;
  overflow: auto;
  @media screen and (max-width :600px) {
    padding-bottom: 60px;
    // max-height: calc(100vh - 140px);
  }


  .route_main {
    // height:100vh;
    overflow: auto;
    height: 100%;
    @media screen and (max-width :600px) {
      padding-bottom: 50px;
      // padding-bottom: 60px;
      // max-height: calc(100vh - 140px);
    }
    // height: 100vh;
    // margin-bottom: 50px;
    // padding-bottom: 50px;
  }

  .header {
    background-color: #3C3C3C;
    // position: sticky;
    // top: 0px;
    height: 50px;
    padding: 10px 15px;
  }

  .sub-left-panel-container {
    .routePersonLogo {
      height: 36px;
      width: 36px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .destination-container {
      // max-height: calc(100vh - 125px);
      padding: 10px;
      overflow-y: auto;

      @media screen and (max-width :600px) {
        // max-height: calc(100vh - 140px);
      }

      .destination-title {
        background-color: #fff;
        gap: 5px;
        color: #3C3C3C;
        border-radius: 20px;
        padding: 5px 10px;

        .location-logo {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .main-scroll {
      max-height: calc(100vh - 345px);
    }

    .image-listing {
      // max-height: calc(100vh - 80px);
      padding-right: 5px;
      display: block;
      padding-top: 30px;

      .list-card {
        margin-top: 15px;
      }

      @media screen and (max-width : 600px) {
        display: none;
      }
    }
  }
}

.mobile-list-container {
  @media screen and (max-width : 600px) {
    // display: none;
    padding-bottom: 70px !important;
  }
  // height: 100vh;
  overflow: auto;
  .image-listing {
    // max-height: 100vh;
    // max-height: calc(100vh - 4.125rem);
    padding: 5px 12px;

    .list-card {
      margin-top: 15px;

      &:first-child {
        margin-top: 0px;
      }
    }
  }

}