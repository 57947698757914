.App {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  // position: relative;
  overflow: hidden;
}

.carousel-height {
  margin-bottom: 50px;
}

.main {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  width: 100%;
  height: 100svh;
  min-height: -webkit-fill-available;
  // min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (max-width: 600px) {
    // padding-bottom: 50px;
  }
}

.home-container {
  height: 100%;
  padding-bottom: 50px;
  background-color: #3c3c3c; // @media screen and (max-width: 600px) {
  //   height: calc(100vh - 50px);
  // }
  .right-container {
    width: 76%;
    display: block;

    @media screen and (max-width: 1024px) {
      width: 68%;
    }

    @media screen and (max-width: 768px) {
      width: 58%;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .left-container {
    width: 24%;

    @media screen and (max-width: 1024px) {
      width: 32%;
    }

    @media screen and (max-width: 768px) {
      width: 42%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      overflow: hidden;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c4c4c4;
}

.cursor-pointer {
  cursor: pointer;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-overlay] {
  border-top-left-radius: var(--rsbs-overlay-rounded);
  border-top-right-radius: var(--rsbs-overlay-rounded);
  display: flex;
  background: rgba(60, 60, 60, 0.85);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);

  width: 90vw;
  margin: 0 auto;
  margin-bottom: 40px;
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 49px;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}

[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: #fff;
}

@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)), 0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'],
[data-rsbs-is-dismissable='false']:matches([data-rsbs-state='opening'], [data-rsbs-state='closing']) {
  & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
    opacity: var(--rsbs-content-opacity);
  }

  & [data-rsbs-backdrop] {
    opacity: var(--rsbs-backdrop-opacity);
  }
}

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

[data-rsbs-backdrop],
[data-rsbs-overlay],
[data-rsbs-root]:after {
  padding: 0px 0px 10px;
  height: auto !important;
  z-index: 100;
}

[data-rsbs-scroll] {
  padding-top: 30px;
  position: relative;
}

.googlemap > svg {
  bottom: calc(5vh + 76px) !important;

  @media screen and (max-width: 600px) {
    bottom: 170px !important;
  }
}

.tooglebntn {
  position: fixed;
  z-index: 10;
  // bottom: 50px;
  bottom: 88px;
  background: rgba(0, 0, 0, 0.52);
  border: none;
  height: 25px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: 1px;
  right: 1px;
  padding-bottom: 30px;
  width: 90vw;
  margin: 0 auto;

  span {
    display: none;
  }

  & .line {
    background-image: url(./assets/svgs/chevron-down.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    top: 0;
    transform: rotate(180deg);
    margin: auto;
  }

  @media (min-width: 600px) {
    display: none;
  }
}

[data-rsbs-header]:before {
  background-image: url(./assets/svgs/chevron-down.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  top: 0;
}

// .closebtn {position: relative;}
.closetoggle {
  position: absolute;
  z-index: 1000 !important;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 30px;
  margin: auto;
  background: transparent;
  border: none;
  opacity: 0;
  overflow: hidden;
}

.dnone {
  display: none !important;
}
