.overlay{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#e4e9e7b3;
    z-index: 1;
    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;  
    }
  }

