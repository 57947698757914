.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  &:hover {
    z-index: 1;
  }
}

.maplableStart {
  margin-bottom: 50px;
}

.maplableEnd {
  margin-bottom: 40px;
}

.googlemap {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  // bottom: 0;
  .list-card-container {
    position: absolute;
    width: 35%;
    margin: 0px 10px;
    margin-bottom: 15% !important;
    bottom: calc(100vh - (95vh));
    transform: translate(-50%);
    left: 50%;
  }

  @media screen and (max-width: 600px) {
    .list-card-container {
      width: 95%;
      // bottom: calc(100vh - (90vh - 40px));
      bottom: 40px;
      // bottom: 15% !important;
      left: 0;
      right: 0;
      margin: 15px 10px;
      transform: unset;
    }
  }
  @media screen and (max-width: 600px) {
    .list-card-container {
      display: flex;
      // height: 49%;
      justify-content: center;
      flex-direction: column;
    }
  }
}
