.carousel-main-container {
  // padding: 24px 0px 8px;
  // margin-bottom: ;
  position: relative;
  .carousel-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  .swiper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 20px;
    padding: 0 10px;
    .carousel-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      cursor: pointer;
      .prev-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        background: rgba(255, 255, 255, 0.85);
        opacity: 0.8;
        line-height: 25.78px;
        font-weight: 700;
        font-size: 22px;
        color: #0000e3;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        &:before {
          content: 'prev';
          font-family: swiper-icons;
          font-size: 22px;
          text-transform: none !important;
          letter-spacing: 0;
          font-variant: initial;
          line-height: 25.78px;
          font-weight: 700;
          margin-right: 8px;
        }
      }
      .prev-next-button-disbled {
        opacity: 0.35 !important;
        cursor: auto;
        pointer-events: none;
      }
      .next-button {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.85);
        opacity: 0.8;
        line-height: 25.78px;
        font-weight: 700;
        font-size: 22px;
        color: #0000e3;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        &:after {
          content: 'next';
          font-family: swiper-icons;
          font-size: 22px;
          text-transform: none !important;
          letter-spacing: 0;
          font-variant: initial;
          font-weight: 700;
          line-height: 25.78px;
          margin-left: 8px;
        }
      }
    }
    .swiper-button-next {
      width: 70px;
      height: 40px;
      right: -20px;
      border-radius: 50% 50% 0 0 / 100% 100% 0 0;
      border-bottom: 0;
      background: #fff;
      transform: rotate(-90deg);
      &::after {
        transform: rotate(90deg);
      }
    }
    .swiper-button-prev {
      width: 70px;
      height: 40px;
      left: -20px;
      border-radius: 50% 50% 0 0 / 100% 100% 0 0;
      border-bottom: 0;
      background: #fff;
      transform: rotate(-270deg);
      &::after {
        transform: rotate(270deg);
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0.85;
      color: black;
      &::after {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.35 !important;
    }
    // .swiper-button-prev.swiper-button-disabled {
    //   // opacity: 0.35 !important;
    // }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 20px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    // object-fit: fill;
    object-fit: cover;
  }
}
