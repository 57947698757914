.list-card-container {
  // background: #ffffff;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  backdrop-filter: blur(2px);
  color: #3c3c3c;
  border-radius: 20px;
  // padding: 10px 20px;
  .devider {
    border-top: 2px solid #c4c4c4;
  }
  .destination-exit {
    padding: 5px 10px;
    background: #f8c339;
    border-radius: 10px;
    display: grid;
    justify-content: left;
  }
}