.image-card-container {
  border-radius: 20px;
  height: 30vh;
  width: 100%; 
  position: relative;
  .location-img {
    width: 100%;
    height: 30vh;
    border-radius: 20px;
    object-fit: fill;
  }

  .view-container {
    padding: 10px;
    position: absolute;
    top: 0;
    width: 60%;

    @media screen and (min-width:2560px) {
      width: 57%;
    }

    .view-text {
      text-align: center;
      width: 30px;
      height: 30px;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      border-radius: 50%;
    }

    .three-d-view {
      width: 55px;
      height: 46px;
    }
  }

  @media screen and (max-width: 600px) {
    height: 30vh;
  }
}