.mobile-nav-wrapper {
  width: 100%;
  padding-inline: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;

  .mobile-nav {
    background: #3c3c3c;
    position: fixed;
    bottom: 0;
    // height: 50px;
    padding: 4px 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
    width: 90vw;
    @media (max-width: 320px) {
      justify-content:center;
    }
    // width: 100%;
    // align-items: center
    // border-radius: 15%;
    .disable-text {
      color: #616d7e !important;
    }
    .bloc-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
      padding: 0 20px;
      text-decoration: none;

      svg {
        width: 30px;
        fill: #fff;
      }
      &.active {
        background: #fff;
        border-radius: 15px;
        color: #3c3c3c;
        svg {
          fill: #3c3c3c;
        }
      }
      // &:hover{
      //   background: #fff;
      //   border-radius: 15px;
      //   color: #3C3C3C;
      //   svg {
      //     fill: #3C3C3C;
      //   }
      // }
    }
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
}
.custom-alert {
  top: 10px !important;
  position: absolute !important;
  z-index: 5 !important;
  right: 10px !important;
  padding: 0.5rem !important;
}
