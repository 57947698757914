@mixin common-font-style($f-weight, $f-size, $l-height) {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: $f-weight !important;
  font-size: $f-size !important;
  line-height: $l-height !important;
}
.display-1 {
  @include common-font-style(700, 4rem, 76px);
}
.display-2 {
  @include common-font-style(400, 3.5rem, 64px);
}

.h1,
h1 {
  @include common-font-style(700, 2rem, 40px);
  margin: 0px !important;
}

.h2,
h2 {
  @include common-font-style(700, 1.75rem, 36px);
  margin: 0px !important;
}

.h3,
h3 {
  @include common-font-style(700, 1.5rem, 32px);
  margin: 0px !important;
}

.h4,
h4 {
  @include common-font-style(700, 1.25rem, 28px);
  margin: 0px !important;
}

.h5,
h5 {
  @include common-font-style(700, 1.125rem, 26px);
  margin: 0px !important;
}
.h6,
h6 {
  @include common-font-style(700, 1rem, 24px);
  margin: 0px !important;
}

.label-s {
  @include common-font-style(600, 0.625rem, 14px);
}

.label-m {
  @include common-font-style(600, 0.75rem, 16px);
}
.label-l {
  @include common-font-style(600, 0.875rem, 18px);
}

.lable-form-s {
  @include common-font-style(500, 0.75rem, 18px);
}

.title {
  @include common-font-style(500, 1.5rem, 33px);
}

.title1 {
  @include common-font-style(500, 1.1rem, 25px);
}
//text-xs 12px lh 16px
.text-xs {
  @include common-font-style(500, 0.75rem, 16px);
}
.text-xs-bold {
  @include common-font-style(700, 0.75rem, 16px);
}
//text-s 14px lh 22px
.text-s {
  @include common-font-style(500, 0.875rem, 22px);
}
//text-m 14px lh 22px
.text-m {
  @include common-font-style(500, 1rem, 22px);
}
//text-m 14px lh 22px
.text-m-bold {
  @include common-font-style(700, 1rem, 19px);
}
//text-l 22px lh 22px
.text-l {
  @include common-font-style(500, 1.375rem, 30px);
}
.lable-form-l {
  @include common-font-style(500, 1rem, 18px);
}
.body-s {
  @include common-font-style(400, 0.875rem, 22px);
}
.body-m {
  @include common-font-style(400, 1rem, 24px);
}
.body-l {
  @include common-font-style(400, 1.125rem, 26px);
}
.body-xl {
  @include common-font-style(400, 1.25rem, 28px);
}
.lead {
  @include common-font-style(600, 0.875rem, 18px);
}
.blockquotes {
  @include common-font-style(500, 1.25rem, 28px);
}
.pre-head {
  @include common-font-style(500, 0.875rem, 20p);
}
.small {
  @include common-font-style(500, 0.75rem, 14px);
}
.tiny {
  @include common-font-style(600, 0.625rem, 12px);
}
// .text-s {
// 	@include common-font-style(500, 0.75rem, 16px, );
// }
// .text-m {
// 	@include common-font-style(500, 0.87rem, 18px, );
// }
// .text-l {
// 	@include common-font-style(500, 1rem, 20px, );
// }
// .text-xl {
// 	@include common-font-style(500, 1.125rem, 22px, );
// }
.btn-text-l {
  @include common-font-style(600, 1rem, 20px);
}
.form-label-s {
  @include common-font-style(600, 0.65rem, 14px);
}
.form-label-m {
  @include common-font-style(600, 0.75rem, 16px);
}
.form-label-l {
  @include common-font-style(600, 0.875rem, 18px);
}
.form-label-xl {
  @include common-font-style(600, 1rem, 20px);
}
.text-capitalize {
  text-transform: capitalize;
}
.ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
